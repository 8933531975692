/* You can add global styles to this file, and also import other style files */
:root {
    --green-fbeeper: #00AA31;
    --green-hub-fbeeper: #248E71 ;
    --danger-hub-fbeeper: #dc3545;
    --success-hub-fbeeper: #248e3c;
    --header-hub-content: #34A835;
    --primeng-hub-green: #4CAF50;
    --info-hub-fbeeper: #248ED0;
    --grey-primeng: #e9ecef;
    --grey-fbeeper: #F7F7F7;
    --gray-fbeeper: #ACACAC;
    --gray-hub-fbeeper: #495057;

    --font-fbeeper: 'Georgia';
    --font-sec-fbeeper: "fontSecFbeeper";

    /* ######################### wajeez palette ########################## */
    --grey-fbeeper: #F7F7F7;
    --Primary_base_01: #00AA31;
    --Primary_base_02: #FF303C;
    --Primary_base_03: #FC7E00;
    --Primary_base_04: #FFD600;
    --Primary_base_05: #32A7F0;
    --Primary_base_06: #414042
    --Primary_base_09: #9747FF;
    --Primary_base_08: #EAB02D;
    --Primary_base_09: #001AFF;

    --black: #000
    --Shade_01: #000000;
    --Shade_02: #565656;
    --Shade_03: #7C7C7C;
    --Shade_04: #ACACAC;
    --Shade_05: #D0D0D0;
    --Shade_06: #E5E5E5;
    --Shade_07: #F7F7F7;
}

@font-face {
    font-family: 'fontSecFbeeper';
    src: url('/assets/fonts/Avenir/Avenir-Medium.ttf') format('truetype');
}
  

body{
    font-family: var(--font-sec-fbeeper) !important;
    font-weight: 500;
    @media (max-width: 1399px) {
        font-size: 0.8rem !important;
    }
    @media (min-width: 1400px) {
        font-size: 1rem !important;
    }
    
}

.bg-green-fbeeper{
    background-color: var(--green-fbeeper);
}

.bg-danger-fbeeper{
    background-color: var(--danger-hub-fbeeper);
    color: white;
}

.color-green-fbeeper{
    color: var(--green-fbeeper);
}

.color-success-hub-fbeeper{
    color: var(--success-hub-fbeeper);
}

.color-danger-hub-fbeeper{
    color: var(--danger-hub-fbeeper);
}

/** button **/
.btn:hover{
    color: white;
}

.btn-green-fbeeper{
    background-color: var(--green-fbeeper);
    color: white;
}

.btn-green-fbeeper:hover{
    background-color: #1f7a34;
    color: white;
}

.btn-success-fbeeper{
    background-color: var(--success-hub-fbeeper);
    color: white;
    font-weight: 600;
}

.btn-success-fbeeper:hover{
    background-color: #156a2d;
    color: white;
}

.btn-default-green-fbeeper, .btn-default-green-fbeeper:hover{
    border-color: var(--success-hub-fbeeper);
    background-color: white;
    color: var(--success-hub-fbeeper);
    font-weight: 600;
}

.btn-default-green-fbeeper:hover{
    background-color: rgba(36, 142, 60, 0.04);
}

.btn-gray-fbeeper{
    background-color: var(--gray-fbeeper);
    color: white;
    font-weight: 600;
}

.btn-gray-fbeeper:hover{
    background-color: #156a2d;
    color: white;
}

.btn-gray-fbeeper:focus, .btn-success-fbeeper:focus, .btn-default-green-fbeeper:focus{
    border-color: var(--primeng-hub-green);
    box-shadow: 0 0 0 0.2rem #b7e0b8;
}

.btn-default-fbeeper, .btn-default-fbeeper:hover{
    color: #565656;
    font-weight: 600;
    box-shadow: none !important;
}

.font-fbeeper{
    font-family: var(--font-sec-fbeeper) !important;
}

.btn-default-primeng-green{
    color: var(--primeng-hub-green);
    border-color: var(--primeng-hub-green);
    background-color: white;
}

.btn-default-primeng-green:focus{
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #b7e0b8;
}

.btn-default-primeng-green:hover{
    background: rgba(76, 175, 80, 0.04);
    color: var(--primeng-hub-green);
    border: 1px solid;
}

.btn-default-info{
    color: var(--info-hub-fbeeper);
    border-color: var(--info-hub-fbeeper);
    background-color: white;
}

.btn-default-info:focus{
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--info-hub-fbeeper);
}

.btn-default-info:hover{
    background: rgba(76, 175, 80, 0.04);
    color: var(--info-hub-fbeeper);
    border: 1px solid;
}

.btn-info, .btn-info:focus{
    color: white;
    border-color: var(--info-hub-fbeeper);
    background-color: var(--info-hub-fbeeper);
}

.btn-info:focus{
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--info-hub-fbeeper);
}

.btn-info:hover{
    background: var(--info-hub-fbeeper);
    color: white;
    border: 1px solid;
}


.btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
}
/** end button **/
/** hub **/

.bg-green-hub-fbeeper{
    background-color: var(--green-hub-fbeeper);
}

.color-green-hub-fbeeper{
    color: var(--green-hub-fbeeper);
}

.btn-green-hub-fbeeper:hover{
    background-color: #107D11;
    color: white;
}

.btn-danger-hub-fbeeper{
    background-color: var(--danger-hub-fbeeper);
    color: white;
}

.btn-danger-hub-fbeeper:hover{
    background-color: #7a1f37;
    color: white;
}

/** global **/
.bgDanger{
    background-color: #e2161d;
    color :white
}
.bgSuccess {
    background-color: limegreen;
    color: white;
}
.bgAccepted {
    background-color: #0a882d;
    color:white;
}
.bgPickUp {
    background-color: #097e65;
    color:white;
}
.bgWarning  {
    background-color: darkorange ;
    color:white;
}
.bgNewOrder  {
    background-color:#10648b;
    color:white;
}
.pistach {
    background-color: #9ce204 ;
    color:white;
    border-color: #9ce204 ;
}
.bgWaiting{
    background-color:#737373;
    color: white;
}
.bgLivreur{
    background-color: #79b003;
    color: white;
}
.pendingOrder{
    background-color: #FFF7ED !important ;
}
.confirmedOrder{
    background-color: rgba(42, 127, 238, 0.23) !important;
}

.selectedOrder{
    background-color: rgb(87, 252, 0, 0.6) !important;
    font-weight: 700;
}
/** primeNg **/
.p-component{
    font-family: var(--font-sec-fbeeper) !important;

    @media (max-width: 1399px) {
        font-size: 0.8rem !important;
    }
    @media (min-width: 1400px) {
        font-size: 1rem !important;
    }

    .pi{
        font-size: 0.7rem;
    }
}

/** customize primeng table **/
.p-datatable .p-datatable-header{
    background: var(--grey-fbeeper);
}
.p-datatable .p-datatable-thead > tr > th{
    background: var(--grey-fbeeper);
}

.p-datatable-tbody > tr {
    border-bottom: 1px solid #e9ecef;

    > td {
        border-bottom: 0 none;
    }
}
.p-datatable .p-datatable-tbody>tr>td{
    border: none;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
    margin-left: 0.2rem;
}

.pi-spinner:before{
    color: var(--success-hub-fbeeper);
}

/** customize primeng progressbar **/
.p-progressbar{
    height: 0.4rem;
    background: var(--grey-fbeeper);
    border-radius: 0px;
}

/** bootstarp **/

/** customize bootstarp radiobutton **/
.form-check-input:checked{
    background-color: var(--primeng-hub-green);
    border-color: var(--primeng-hub-green);
}

/** customize bootstarp tabulation **/
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: white;
    background-color: var(--green-hub-fbeeper);
}

/** **/
.form-control:focus, .form-check-input:focus, .form-select:focus{
    border-color: var(--primeng-hub-green);
    box-shadow: 0 0 0 0.25rem rgba(76, 175, 80,.25);
}
/** end bootstarp **/



@media (max-width: 960px) {
    .dialog45{
        width: 100vw; 
    }
    .dialog35{
        width: 75vw; 
    }
}
@media (min-width: 961px) {
    .dialog45{
        width: 45vw; 
    }
    .dialog35{
        width: 35vw; 
    }
    .p-column-title{
        display: none;
    }
}

.responsiveFont{
    @media (max-width: 1399px) {
        font-size: 0.8rem !important;
    }
    @media (min-width: 1400px) {
        font-size: 0.9rem !important;
    }
}